import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IMapProductPricesProps } from '../product-price/ProductPrice'
import { BaseCardCounterProps, IBaseProductCard } from './baseTypes'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { IWithCheckbox } from './withCheckbox'
import { ICardWithProduct } from './withProduct'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { Counter } from '../counter/Counter'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { Box } from '@mui/material'
import { Horizontal, Mobile } from './layouts'
import styles from '../product-card/withCheckbox.module.scss'
import stylesCheckbox from './withCheckbox.module.scss'
import { Bottom } from './layouts/Bottom'
import {
  MaybeCompleteCartTemplateDetailsItem,
  MaybeCompleteCartTemplateDetailsItemProduct,
} from '@obeta/models/lib/schema-models'
import { AddItemsToCartButton } from '../add-items-to-cart-button/AddItemsToCartButton'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { productHasTecSelect } from '@obeta/utils/lib/productHasTecSelect'
import { ReactComponent as TecSelectIcon } from '@obeta/assets/icon/tec_select_small.svg'
import cartTemplateStyles from './makeCartTemplateItem.module.scss'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { isCompleteCartTemplateDetailsItem } from '@obeta/models/lib/schema-models/utils/isCompleteCartTemplateDetailsItem'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { Checkbox } from '../checkbox/Checkbox'
import clsx from 'clsx'
import { IdsTransferProductButton } from '../ids/IdsTransferProductButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import makeItemStyles from './makeItem.module.scss'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import { useProductCard } from '@obeta/data/lib/hooks/useProductCard'
import { AddCartContextEnum, addProductToCartGraphQL } from '@obeta/data/lib/actions'
import { trackClick } from '@obeta/utils/lib/tracking'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import useStateWithCallbackOnUpdate from '@obeta/data/lib/hooks/useStateWithCallbackOnUpdate'
import { CustomLabel, LabelInCart, LabelTimesPurchased, LabelTopseller } from '../label/Label'
import { useShoppingCartsV2 } from '@obeta/data/lib/hooks/useShoppingCartsV2'
import { extractNumberFromText } from '@obeta/utils/lib/extractNumberFromText'

interface ICartTemplateItemProps
  extends Omit<
      ICardWithProduct,
      | 'productUnit'
      | 'counter'
      | 'onAddClicked'
      | 'onInfoClicked'
      | 'product'
      | 'onDeleteClicked'
      | 'productAmount'
      | 'stocks'
      | 'layout'
      | 'settings'
    >,
    IWithCheckbox {
  changeProductAmount: (id: string, amount: number) => void
  cartTemplateItem: MaybeCompleteCartTemplateDetailsItem
  onDeleteClicked: (cartTemplateItem: MaybeCompleteCartTemplateDetailsItem) => void
  loadingPrices?: boolean
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  onCounterValidation?: BaseCardCounterProps['onInputValidation']
  selectedStore: StoreV2 | undefined
  user: UserV2 | null
  isLoggedIn: boolean
  mobile: boolean
  tablet: boolean
  withCheckbox: boolean
  selected: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  carts: ShoppingCartV2[]
  selectedCart: ShoppingCartV2 | undefined
}

export interface ICartTemplateItemForPdfProps {
  isCartItemForPdf?: boolean
  shoppingCartItemAmount?: number
  shoppingCartItemMinimumAmount?: number
  isCartItemForOrderPdf?: boolean
  orderPdfSupplierData?: { type: string; value: React.ReactText }
  orderWithPrice?: boolean
  orderItemAmount?: number
}

export const makeTemplateItem = (
  Card: React.FC<IBaseProductCard & ICartTemplateItemForPdfProps>
): React.FC<ICartTemplateItemProps & ICartTemplateItemForPdfProps> => {
  return (props) => {
    const {
      shoppingCartItemAmount,
      shoppingCartItemMinimumAmount,
      cartTemplateItem,
      onDeleteClicked,
      changeProductAmount,
      carts,
      selectedCart,
      properties = [],
      loadingPrices,
      onProductImageClicked,
      onCounterValidation,
      selectedStore,
      isCartItemForPdf,
      isCartItemForOrderPdf,
      orderWithPrice,
      user,
      isLoggedIn,
      mobile,
      tablet,
      withCheckbox,
      selected,
      onChange,
      orderPdfSupplierData,
      orderItemAmount,
      ...restCardProps
    } = props

    const layoutType = 'horizontal'
    const dispatch = useDispatch()
    const { setAmountRoundUpNotification } = useProductCard()
    const { t } = useTranslation()
    const addItemsToCartButtonRef = useRef<HTMLDivElement>(null)
    const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')
    const isShowCustomLabels = useFeatureToggle('UseShowCustomLables')
    const isShowLabelAlreadyinCart = useFeatureToggle('UseShowLabelAlreadyinCart')
    const isShowLabelXTimesPurchased = useFeatureToggle('UseShowLabelXTimesPurchased')
    const isShowLabelTopseller = useFeatureToggle('UseShowLabelTopseller')

    const isLoadingPrices = Boolean(loadingPrices)
    const mobileOrTablet = mobile || tablet
    const { handleClick: showDropdownTemplates, ...dropdownTemplates } = usePopoverState()
    const maybeCompleteCartTemplateProduct: MaybeCompleteCartTemplateDetailsItemProduct =
      cartTemplateItem.product
    const { desktop } = useBreakpoints()

    const priceDimension = isCompleteCartTemplateDetailsItem(cartTemplateItem)
      ? cartTemplateItem.product.priceDimension
      : undefined
    const productPrice = isCompleteCartTemplateDetailsItem(cartTemplateItem)
      ? cartTemplateItem.product.prices
      : undefined
    const unit = isCompleteCartTemplateDetailsItem(cartTemplateItem)
      ? cartTemplateItem.product.unit
      : undefined
    const stock = isCompleteCartTemplateDetailsItem(cartTemplateItem)
      ? cartTemplateItem.product.stock
      : undefined
    const stockAvailabilityEstimate = isCompleteCartTemplateDetailsItem(cartTemplateItem)
      ? cartTemplateItem.product.stockAvailabilityEstimate
      : undefined

    const prices: IMapProductPricesProps['prices'] = [
      {
        type: 'PurchasePrice',
        value: productPrice?.netPrice,
        loading: isLoadingPrices,
        unit: unit ?? '',
        oldValue: productPrice?.strikeThroughPrice,
      },
      {
        type: 'ListPrice',
        value: productPrice?.listPrice,
        loading: isLoadingPrices,
        unit: unit ?? '',
      },
    ]

    const [productAmount, setProductAmount] = useStateWithCallbackOnUpdate(cartTemplateItem.amount)

    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const mobileCounterAvailable = Boolean(mobileOrTablet)

    const showIdsTransferBackButton = useIsIdsTransferBackPossible()
    useEffect(() => {
      if (cartTemplateItem.amount !== productAmount) {
        setProductAmount(cartTemplateItem.amount)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartTemplateItem.amount])

    useEffect(() => {
      if (cartTemplateItem.amount === productAmount) {
        // make sure that update is not called unnecessarily
        return
      }
      changeProductAmount(cartTemplateItem.id, productAmount)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productAmount, cartTemplateItem.id])

    const minimumAmount = maybeCompleteCartTemplateProduct.minimumAmount

    const stocksMap = useStocksMap({
      isOnlineCurrentNotAvailable: maybeCompleteCartTemplateProduct.isCurrentlyNotAvailable,
      stocks: stock ?? [],
      user,
    })

    const onAddToCart = useCallback(
      (cart: ShoppingCartV2, amount?: number) => {
        trackClick('cart-template-details-add-to-cart', {
          orderItemId: cartTemplateItem.id,
          cartId: cart.id,
          amount,
        })

        if (amount) {
          dispatch(
            addProductToCartGraphQL({
              cartId: cart.id,
              items: [
                {
                  sapId: cartTemplateItem.product.sapId,
                  amount,
                  title: cartTemplateItem.product.title,
                },
              ],
              context: AddCartContextEnum.Generic,
            })
          )
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [cartTemplateItem]
    )

    const onCounterSubmit = useCallback(
      (amount: number) => {
        if (selectedCart) {
          if (isAddToCartProcessIndicator) {
            // Just in case we submit the counter, we should add the item to the cart with button animation.
            // The easiest way is to emulate a button click here, but all the data should be up-to-date, so we update the amount first.
            setProductAmount(amount, () => {
              const buttonElement = addItemsToCartButtonRef.current?.querySelector('button')
              if (buttonElement) buttonElement.click()
            })
          } else {
            onAddToCart(selectedCart, amount)
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isAddToCartProcessIndicator, selectedCart]
    )

    const leftAdornments = withCheckbox
      ? [
          <Checkbox
            key={cartTemplateItem.id}
            checked={selected}
            className={stylesCheckbox.checkbox}
            onChange={onChange}
          />,
        ]
      : null

    const actions: JSX.Element[] = []

    const [topAdornments] = useState<JSX.Element[]>([])

    const tecSelectAvailable = productPrice?.tecSelect && productHasTecSelect(productPrice)
    if (tecSelectAvailable && !topAdornments.some((item) => item.key === 'tec-select')) {
      topAdornments.push(<TecSelectIcon key="tec-select" />)
    }

    const bottomExtension = (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!desktop && showIdsTransferBackButton && (
          <div className={clsx(makeItemStyles.subActions, tablet && makeItemStyles.compact)}>
            {showIdsTransferBackButton && (
              <div className={makeItemStyles.transferIdsButton}>
                <IdsTransferProductButton sapId={cartTemplateItem.id} amount={productAmount} />
              </div>
            )}
          </div>
        )}
      </>
    )
    const { activeCart } = useShoppingCartsV2()
    const product = cartTemplateItem.product

    if (isLoggedIn && activeCart) {
      const isInCart = activeCart.items.some((cartItem) => cartItem.sapId === product.sapId)
      if (
        isInCart &&
        isShowLabelAlreadyinCart &&
        topAdornments.some((item) => item.key === 'label-in-cart')
      ) {
        topAdornments.push(
          <div key="label-in-cart">
            <LabelInCart />
          </div>
        )
      }
    }

    const customLabels = product.labels?.filter(
      (label) => 'color' in label && label.color !== undefined
    )

    if (customLabels && isShowCustomLabels) {
      // eslint-disable-next line array-callback-return
      customLabels.forEach((customLabel) => {
        !topAdornments.some((item) => item.key === customLabel.text) &&
          topAdornments.push(
            <div key={customLabel.text}>
              <CustomLabel
                color={'color' in customLabel ? customLabel.color : ''}
                text={customLabel.text}
              />
            </div>
          )
      })
    }

    const boughtXTimesLabel = product.labels?.find(
      (label) => 'type' in label && label.type === 'boughtXTimes'
    )
    if (
      boughtXTimesLabel &&
      !topAdornments.some((item) => item.key === 'boughtXTimes') &&
      isShowLabelXTimesPurchased
    ) {
      const count = extractNumberFromText(boughtXTimesLabel.text)

      if (count !== null) {
        topAdornments.push(
          <div key="boughtXTimes">
            <LabelTimesPurchased count={count} />
          </div>
        )
      }

      const topseller = product.labels?.find(
        (label) => 'type' in label && label.type === 'topseller'
      )
      if (
        topseller &&
        !topAdornments.some((item) => item.key === 'topseller') &&
        isShowLabelTopseller
      ) {
        topAdornments.push(
          <div key="topseller">
            <LabelTopseller />
          </div>
        )
      }
    }

    return (
      <Card
        {...restCardProps}
        data-testid="template-item-card"
        storeAddress={selectedStore?.address.name1 ?? ''}
        authenticated={isLoggedIn}
        title={maybeCompleteCartTemplateProduct.title}
        properties={[
          {
            type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
            value: maybeCompleteCartTemplateProduct.dehaId,
          },
          ...properties,
        ]}
        isSendable={maybeCompleteCartTemplateProduct.isSendable}
        isCutProduct={maybeCompleteCartTemplateProduct.isCutProduct}
        productImage={{
          componentType: 'card',
          src: maybeCompleteCartTemplateProduct.imageData?.images[0]?.large ?? '',
          alt: maybeCompleteCartTemplateProduct.title,
          supplierImage: maybeCompleteCartTemplateProduct.supplierImageData?.large,
          supplierId: maybeCompleteCartTemplateProduct.supplierId,
          oxomiId: maybeCompleteCartTemplateProduct.oxomiId,
          className: '',
          onClick: onProductImageClicked,
        }}
        productType={maybeCompleteCartTemplateProduct.type as ProductType}
        stocks={stocksMap}
        stockAvailabilityEstimate={stockAvailabilityEstimate}
        showIdsTransferButton
        onAddClicked={showDropdownTemplates}
        onInfoClicked={null}
        productAmount={productAmount}
        counter={
          isCartItemForPdf || !isLoggedIn ? null : (
            <Box sx={{}}>
              <Counter
                stretchHorizontal={true}
                amount={productAmount}
                minimumAmount={minimumAmount || 0}
                initialAmount={null}
                variant={mobileOrTablet ? 'big' : 'small'}
                readonly={mobileCounterAvailable}
                disabled={false}
                onInputValidation={(valid, amountToUse, amount) => {
                  if (!valid && cartTemplateItem.product) {
                    setAmountRoundUpNotification(
                      cartTemplateItem.product?.sapId,
                      amountToUse,
                      amount,
                      cartTemplateItem.product?.unit
                    )
                  }
                }}
                changeProductAmount={setProductAmount}
                onSubmit={onCounterSubmit}
                onTextFieldClicked={() => {
                  setShowMobileCounter(true)
                }}
              />
              {mobileCounterAvailable && (
                <PopoverMobileCounter
                  open={showMobileCounter}
                  onClose={function () {
                    setShowMobileCounter(false)
                  }}
                  unit={unit ?? ''}
                  initialAmount={productAmount}
                  minimumAmount={minimumAmount || 0}
                  maxAcceptableAmount={9999}
                  onAccept={function (value: number): void {
                    setProductAmount(value)
                    setShowMobileCounter(false)
                  }}
                />
              )}
            </Box>
          )
        }
        onDeleteClicked={() => onDeleteClicked(cartTemplateItem)}
        isCartItemForPdf={isCartItemForPdf}
        isCartItemForOrderPdf={isCartItemForOrderPdf}
        settings={{
          labels: 'only-icon',
          statusesDirection: mobile ? 'vertical' : 'horizontal',
          stretch: true,
        }}
        layout={(layoutProps) => {
          const bottomAdornments =
            layoutProps.authenticated && maybeCompleteCartTemplateProduct
              ? [
                  <AddItemsToCartButton
                    key="add-items-to-cart-button"
                    ref={addItemsToCartButtonRef}
                    carts={carts}
                    selectedCart={selectedCart}
                    mobile={false}
                    product={maybeCompleteCartTemplateProduct}
                    productAmount={productAmount}
                    size={mobileOrTablet ? 'large' : 'small'}
                    updateCart={onAddToCart}
                    algoliaUserToken={user?.algoliaUserToken}
                  />,
                ]
              : null

          const renderPricesBlock = createRenderPricesBlock(
            {
              prices,
              pricePermissions: user?.permissions?.Global_canReadPrices,
              currency: productPrice?.currency || '',
              priceDimension,
            },
            layoutType,
            isLoggedIn,
            !!isCartItemForPdf,
            isCartItemForOrderPdf,
            orderWithPrice
          )
          const actionsElements = layoutProps.actionsElements.concat(actions)

          if (mobile) {
            return (
              <>
                <Mobile
                  {...layoutProps}
                  leftAdornments={leftAdornments}
                  topAdornments={topAdornments}
                  bottomAdornments={bottomAdornments}
                  actionsElements={actionsElements}
                  imageSize={
                    layoutProps.authenticated
                      ? IMAGE_SIZE_AUTHENTICATED
                      : IMAGE_SIZE_UNAUTHENTICATED
                  }
                  values={renderPricesBlock()}
                  bottomExtension={bottomExtension}
                />
                <DropdownTemplatesBase
                  dropdown={dropdownTemplates}
                  mobile={false}
                  productsToAdd={[{ productId: cartTemplateItem.productId, amount: productAmount }]}
                  templatesType={DropdownTemplatesType.ADD_ARTICLES}
                />
              </>
            )
          }

          if (tablet) {
            const { counter, ...restLayoutProps } = layoutProps
            return (
              <>
                <Horizontal
                  {...restLayoutProps}
                  className={styles.tablet}
                  counter={null}
                  leftAdornments={leftAdornments}
                  topAdornments={topAdornments}
                  bottomAdornments={[
                    <Bottom
                      key="bottom-adornments"
                      className={cartTemplateStyles.bottomTablet}
                      direction="horizontal"
                    >
                      {counter}
                      {bottomAdornments}
                    </Bottom>,
                  ]}
                  bottomRightAdornments={null}
                  mobile={true}
                  actionsElements={actionsElements}
                  mapValues={renderPricesBlock}
                  bottomExtension={bottomExtension}
                />
                <DropdownTemplatesBase
                  dropdown={dropdownTemplates}
                  mobile={false}
                  productsToAdd={[{ productId: cartTemplateItem.productId, amount: productAmount }]}
                  templatesType={DropdownTemplatesType.ADD_ARTICLES}
                />
              </>
            )
          }

          return (
            <>
              <Horizontal
                {...layoutProps}
                leftAdornments={leftAdornments}
                topAdornments={topAdornments}
                bottomRightAdornments={bottomAdornments}
                bottomAdornments={null}
                mobile={true}
                actionsElements={actionsElements}
                mapValues={renderPricesBlock}
                isCartItemForPdf={isCartItemForPdf}
                isCartItemForOrderPdf={isCartItemForOrderPdf}
                shoppingCartItemMinimumAmount={shoppingCartItemMinimumAmount}
                shoppingCartItemAmount={shoppingCartItemAmount}
                orderPdfSupplierData={orderPdfSupplierData}
                orderWithPrice={orderWithPrice}
                orderItemAmount={orderItemAmount}
              />
              <DropdownTemplatesBase
                dropdown={dropdownTemplates}
                mobile={false}
                productsToAdd={[{ productId: cartTemplateItem.productId, amount: productAmount }]}
                templatesType={DropdownTemplatesType.ADD_ARTICLES}
              />
            </>
          )
        }}
        {...restCardProps}
      />
    )
  }
}
